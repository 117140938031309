import React, { createContext, FunctionComponent, useEffect } from "react";
import { useSelector } from "react-redux";
import useSWR from "swr";
import { useAppDispatch } from "@/hooks/redux";
import { setCompletion, setSubmissionsForProblem } from "@/modules/user/slice";
import { userInfoSelector } from "@/modules/user/selectors";
import { Submission } from "@/middleware/types";
import { axiosInstance } from "@/utils/fetch";
import { UserSubmissionState } from "@/types";

const UserSubmissionContext = createContext<Partial<UserSubmissionState>>({});
const { Provider } = UserSubmissionContext;

interface Props {
  problemId?: string;
}

interface PastSubmissions {
  isComplete: boolean;
  submissions: Submission[];
}

interface Progress {
  completionStatus: { [key: string]: boolean };
}

const BASE_URL = process.env.NEXT_PUBLIC_NEST_API_URL;

const UserSubmissionProvider: FunctionComponent<Props> = ({
  problemId,
  children,
}) => {
  const userInfo = useSelector(userInfoSelector);
  const dispatch = useAppDispatch();

  const { data: submissionsData, mutate: submissionsMutate } = useSWR<{
    data: PastSubmissions;
  }>(
    userInfo && problemId
      ? [`${BASE_URL}/submissions/problem/${problemId}`]
      : null,
    axiosInstance()
  );
  const { data: progressData, mutate: progressMutate } = useSWR<{
    data: Progress;
  }>(userInfo ? [`${BASE_URL}/users/progress`] : null, axiosInstance());

  useEffect(() => {
    const completionStatus = progressData?.data?.completionStatus;
    if (userInfo && completionStatus) {
      dispatch(setCompletion(completionStatus));
    }
  }, [progressData, userInfo]);

  useEffect(() => {
    const submissions = submissionsData?.data?.submissions;
    if (userInfo && submissions) {
      dispatch(setSubmissionsForProblem({ id: problemId, submissions }));
    }
  }, [submissionsData, userInfo]);

  return (
    <Provider
      value={{
        submissionsMutate,
        progressMutate,
      }}
    >
      {children}
    </Provider>
  );
};

export { UserSubmissionContext, UserSubmissionProvider };
